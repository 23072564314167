@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-Lt.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-LtIt.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-Md.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-MdIt.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-Bd.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-Hv.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-HvIt.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Co";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-LtCn.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Ex";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-Ex.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Ex";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-LtEx.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Ex";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-MdEx.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Cn";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-LtCn.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Cn";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-Cn.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Cn";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-MdCn.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Cn";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-BdCn.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Cn";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-HvCn.otf")
    format("opentype");
}
@font-face {
  font-family: "Helvetica Neue LT Std Cn O";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/HelveticaNeueLTStd/Linotype - HelveticaNeueLTStd-LtCnO.otf")
    format("opentype");
}
/******************/
/* Font variables */
/******************/
/* $helveticaNeueLTstd: "Helvetica Neue LT Std", Helvetica, Arial, sans-serif; */
