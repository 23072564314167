:root {
  --gray1: #eae6e6;
  --gray3: #bfbfbf;
  --gray4: #666;
  --mutedDate: #808080;
  --graySections: #f2f2f2;

  --darkBlue: #002b49;
  --darkBlue2: #002549;

  --filtersBlue: #78d1ff;
  --hover-blue: #0085ca;
  --light-blue: #0085c9;

  --black1: #292929;

  --yellowCategory: #fac40b;
  --orange1: #de841e;
  --orange2: #cf7f00;
  --orange3: #be7a22;

  --darkBlueHome: #00244a;
  --white: #fff;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: "Helvetica Neue LT Std Cn", Arial, sans-serif;
  overflow-x: hidden;
  position: initial !important;
}

#onetrust-banner-sdk{

  position: fixed !important;
  top: 0 !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.iconMedia{
  background-color: white;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--darkBlue2);
}

.icon {
  width: 20px;
  height: 20px;
}